import React from 'react'
import { Layout } from '@douyinfe/semi-ui';
import { Outlet } from 'react-router-dom'

export default function AuthTemplate() {

    const { Footer} = Layout;

    const commonStyle = {
        height: 64,
        lineHeight: '64px',
        background: 'var(--semi-color-fill-0)'
    };

    const centerStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100dvh - 64px - 16px)',
    }

    return (
        <Layout className="components-layout-demo">
            <Layout style={{ display: 'flex'}}>
                <div style={centerStyle}>
                    <Outlet/>
                </div>
            </Layout>
            <Footer style={commonStyle}>Footer</Footer>
        </Layout>
    );
}