import React, { useState } from 'react';
import axios from 'axios';
import { compareArray } from '../../utils/helper';

import { Card, Button, Row, Col } from '@douyinfe/semi-ui';
import { Form } from '@douyinfe/semi-ui';

import { useAtom } from 'jotai'
import { jwtTokenAtom } from '../../atom'
import {useNavigate} from "react-router-dom";

import { passkeyLogin } from '../../utils/helper';

export default function AuthLogin() {

    const [ username, setUsername] = useState('');
    const [ password, setPassword] = useState('');

    const [ authMethod, setAuthMethod ] = useState([]);

    const [ , setJwtToken ] = useAtom(jwtTokenAtom)

    const removeFromAuthMethod = (value) => {
        setAuthMethod(authMethod.filter(item => item !== value));
    }

    const renderAuthMethod = () => {
        switch (true) {
            case compareArray(authMethod, ['Fido2', 'Password']) || compareArray(authMethod, ['Password', 'Fido2']):
                return (
                    <div className={"grid"}>
                        <Row gutter={16}>
                            <Col lg={12} span={24}>
                                <Button type="primary" className="btn-margin-right" style={{ margin: '0 auto', width: '100%' }} onClick={() => removeFromAuthMethod('Fido2')}>继续使用密码登录</Button>
                            </Col>
                            <Col lg={12} span={24}>
                                <Button type="primary" className="btn-margin-right" style={{ margin: '0 auto', width: '100%' }} onClick={submitWebAuthNLogin}>通过通行密钥登录</Button>
                            </Col>
                        </Row>
                    </div>
                )
            case compareArray(authMethod, ['Password']):
                return (
                    <div>
                        <Form.Input field='password' label='密码' type='password' onChange={(e) => setPassword(e)}/>
                        <Button type="primary" className="btn-margin-right" style={{ margin: '0 auto', width: '100%' }} onClick={submitPasswordLogin}>登录</Button>
                    </div>
                )
            case compareArray(authMethod, ['Fido2']):
                return (
                    <Button type="primary" className="btn-margin-right" style={{ margin: '0 auto', width: '100%' }} onClick={submitWebAuthNLogin}>通过通行密钥登录</Button>
                )
            default:
                return <FetchAuthMethods setAuthMethod={setAuthMethod} username={username} />
        }
    }

    const submitWebAuthNLogin = async (e) => {
        e.preventDefault();
        const loginResponse = await axios.post(process.env.REACT_APP_API_HOST + '/api/v1/auth/webauthn/login/challenge', {
            username
        });
        const { options, sessionId } = loginResponse.data.data;

        const finalOptions = await passkeyLogin(options, sessionId);

        await axios.post(process.env.REACT_APP_API_HOST + '/api/v1/auth/webauthn/login/submit', finalOptions)
            .then(res => {
                if (res.data.code === 200) {
                    let token = res.headers['authorization'];
                    alert(res.data.data)
                    setJwtToken(token)
                    navigate('/dashboard')
                } else {
                    alert(res.data.data)
                }
            });
    };

    const submitPasswordLogin = () => {
        axios.post( process.env.REACT_APP_API_HOST + '/api/v1/auth/legacy/login', {
            username,
            password
        }).then( res => {
            if (res.data.code === 200) {
                let token = res.headers['Authorization'];
                alert("登录成功")
                console.log("token:" + token)
            } else {
                alert(res.data.message)
            }
        })
    }

    const navigate = useNavigate()

    return (
        <Card style={{ minHeight: '300px', width: '30vw', minWidth: '250px' }}>
            <h1>登录</h1>
            <Form layout='vertical'>
                <Form.Input field='username' label='用户名' onChange={(e) => setUsername(e)}/>
                { renderAuthMethod() }
            </Form>
        </Card>
    );
}

const FetchAuthMethods = ({ setAuthMethod, username }) => {
    const fetchAuthMethods = () => {
        axios.post(process.env.REACT_APP_API_HOST + '/api/v1/auth/request', {
            username
        }).then(res => {
            if (res.data.code === 200) {
                console.log(res.data.data.authenticators)
                setAuthMethod(res.data.data.authenticators)
            } else {
                alert(res.data.message)
            }
        });
    }

    return (
        <Button type="primary" className="btn-margin-right" style={{ margin: '0 auto', width: '100%' }} onClick={fetchAuthMethods}>继续</Button>
    )
}
