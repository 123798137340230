// axiosSetup.js
import axios from 'axios';
import { getDefaultStore } from 'jotai';
import { jwtTokenAtom } from './atom';

const setupAxiosInterceptors = () => {
    const store = getDefaultStore();

    axios.interceptors.request.use(
        config => {
            const token = store.get(jwtTokenAtom);
            if (token) {
                config.headers['Authorization'] = `${token}`;
            }
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        response => {
            // Check if HTTP status code is 200
            if (response.status === 200) {
                const { code } = response.data;
                // Check the code inside the data
                if (code === 200) {
                    return response;
                } else if (code === 401) {
                    window.location.href = '/auth/login'
                } else {
                    const error = new Error(`API OK, Error ${code}`);
                    error.response = response;
                    return Promise.reject(error);
                }
            } else {
                return Promise.reject(new Error(`API Error ${response.status}`))
            }
        },
        error => {
            // 什么情况才会触发？
            if (error.response) {
                return Promise.reject(new Error(`onRejected HTTP error: ${error.response.status}`));
            } else {
                return Promise.reject(error);
            }
        }
    );
};

export default setupAxiosInterceptors;
