import React from 'react';
import {RouterProvider, createRoutesFromElements, createBrowserRouter, Route, Navigate} from 'react-router-dom';

import WebTemplate from './templates/WebTemplate';
import Dashboard from './pages/Dashboard';
import Account from './pages/Account';

import AuthTemplate from './templates/AuthTemplate';
import AuthLogin from './pages/auth/AuthLogin';
import AuthRegister from './pages/auth/AuthRegister';

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/">
            <Route index element={<Navigate to="/dashboard" />} />
            <Route path="dashboard" element={<WebTemplate />}>
                <Route index element={<Dashboard />} />
            </Route>
            <Route path="account" element={<WebTemplate />}>
                <Route index element={<Account />} />
            </Route>
            <Route path="auth" element={<AuthTemplate />}>
                <Route path="login" element={<AuthLogin />} />
                <Route path="register" element={<AuthRegister />} />
            </Route>
        </Route>
    )
)

function App() {
  return (
      <RouterProvider router={router} />
  );
}

export default App;
