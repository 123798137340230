import React, {useState} from 'react'
import axios from "axios";
import {Avatar, Button, Col, Divider, Dropdown, Form, Modal, Row, Table} from "@douyinfe/semi-ui";
import {IconMore} from "@douyinfe/semi-icons";

import {jwtTokenAtom} from "../atom";
import {useAtom} from "jotai";
import {compareArray, passkeyLogin, passkeyWTF} from "../utils/helper";

function AccountPasskey({ setVerificationVisible }) {

    const addWebAuthN = async (e) => {
        e.preventDefault();
        // use with jwtToken
        try {
            const addResponse = await axios.get(process.env.REACT_APP_API_HOST + '/api/v1/account/webauthn/credentials/challenge')
            const { options, sessionId } = addResponse.data.data;
            const nickNameRandom = Math.random().toString(36).substring(7)
            const makeCredentialOptions =  await passkeyWTF(options, sessionId, nickNameRandom)
            await axios.post(process.env.REACT_APP_API_HOST + '/api/v1/account/webauthn/credentials', makeCredentialOptions)
        } catch (e) {
            if (e.response && e.response.data) {
                const {code} = e.response.data;
                if (code === 402) {
                    setVerificationVisible(true)
                }
            }
        }
    };

    const [ passkeys, setPasskeys ] = React.useState([])

    const fetchPasskeys = () => {
        axios.get(process.env.REACT_APP_API_HOST + '/api/v1/account/webauthn/credentials')
            .then(response => {
                if (response.data.code === 200) {
                    setPasskeys(response.data.data)
                }
            })
    }

    const deletePasskey = (id) => {
        axios.delete(process.env.REACT_APP_API_HOST + '/api/v1/account/webauthn/credentials/' + id)
    }

    const columns = [
        {
            title: '备注',
            dataIndex: 'nickName',
            render: (text, record, index) => {
                return (
                    <div>
                        <Avatar
                            size="small"
                            shape="square"
                            src={record.nameIconSrc}
                            style={{ marginRight: 12 }}
                        ></Avatar>
                        {text}
                    </div>
                );
            },
        },
        {
            title: '添加日期',
            dataIndex: 'regDate',
        },
        {
            title: '',
            dataIndex: 'operate',
            render: (text, record, index) => {
                return (
                    <Dropdown
                        trigger={'click'}
                        render={
                            <Dropdown.Menu>
                                <Dropdown.Item>编辑</Dropdown.Item>
                                <Dropdown.Item onClick={() => deletePasskey(record.id)}>删除</Dropdown.Item>
                            </Dropdown.Menu>
                        }
                    >
                        <Button icon={<IconMore />} />
                    </Dropdown>
                );
            },
        },
    ];

    React.useEffect(() => {
        fetchPasskeys()
    },[])

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h3>Passkeys</h3>
                <Button onClick={addWebAuthN}>Add Passkey</Button>
            </div>
            <h5>Passkeys are a password replacement that validates your identity using touch, facial recognition, a device password, or a PIN.</h5>
            <Table columns={columns} dataSource={passkeys} pagination={false} />
        </div>
    );
}

function AccountPassword({ setVerificationVisible }) {

    const [ passwordVisible, setPasswordVisible ] = React.useState(false)

    return (
        <div>
            <h3>密码</h3>
            <Button onClick={() => setPasswordVisible(true)}>更改您的密码</Button>
            <PasswordModal passwordVisible={passwordVisible} setPasswordVisible={setPasswordVisible} setVerificationVisible={setVerificationVisible}/>
        </div>
    );
}

function AccountEmail() {
    return (
        <div>
            <h3>Email</h3>
            <h5>Change your email address.</h5>
        </div>
    )
}

function DevAccountJWT() {
    const [ jwtInput, setJWTInput ] = React.useState('')
    const [ , setJWT ] = useAtom(jwtTokenAtom)

    const submitSetJWT = () => {
        setJWT(jwtInput)
    }

    return (
        <div>
            <h3>JWT</h3>
            <h5>Enter a JWT to switch</h5>
            <input type="text" value={jwtInput} onChange={(e) => setJWTInput(e.target.value)} />
            <Button onClick={submitSetJWT}>Set JWT</Button>
        </div>
    )
}

function VerificationModal({ visible, setVisible}) {

    const [ jwt, setJWT] = useAtom(jwtTokenAtom)
    const [ authMethod, setAuthMethod ] = useState([]);
    const [ username, setUsername ] = useState('')
    const [ password, setPassword ] = useState('')

    const decodeJWT = () => {
        if (jwt) {
            const jwtSplit = jwt.split('.')
            const jwtPayload = JSON.parse(atob(jwtSplit[1]))
            setUsername(jwtPayload['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'])
        }
    }

    const removeFromAuthMethod = (value) => {
        setAuthMethod(authMethod.filter(item => item !== value));
    }

    const submitPasswordLogin = () => {
        axios.post( process.env.REACT_APP_API_HOST + '/api/v1/auth/legacy/login', {
            username,
            password
        }).then( res => {
            if (res.data.code === 200) {
                let token = res.headers['Authorization'];
                setJWT(token)
                setVisible(false)
            } else {
                alert(res.data.message)
            }
        })
    }

    const submitWebAuthNLogin = async (e) => {
        const loginResponse = await axios.post(process.env.REACT_APP_API_HOST + '/api/v1/auth/webauthn/login/challenge', {
            username
        });
        const { options, sessionId } = loginResponse.data.data;

        const finalOptions = await passkeyLogin(options, sessionId);

        await axios.post(process.env.REACT_APP_API_HOST + '/api/v1/auth/webauthn/login/submit', finalOptions)
            .then(res => {
                if (res.data.code === 200) {
                    let token = res.headers['authorization'];
                    alert(res.data.data)
                    setJWT(token)
                    setVisible(false)
                } else {
                    alert(res.data.data)
                }
            });
    };

    React.useEffect(() => {
        if (visible) {
            decodeJWT()
            axios.post(process.env.REACT_APP_API_HOST + '/api/v1/auth/request', {
                username
            }).then(res => {
                if (res.data.code === 200) {
                    setAuthMethod(res.data.data.authenticators)
                    setUsername(username)
                } else {
                    alert(res.data.message)
                }
            });
        }
        // 这里的实现方法比较诡异，希望后期更改。
        // eslint-disable-next-line
    }, [visible]);

    return (
        <Modal
            title="验证您的身份"
            visible={visible}
            onCancel={() => setVisible(false)}
            closeOnEsc={true}
        >
            {
                compareArray(authMethod, ['Fido2', 'Password']) || compareArray(authMethod, ['Password', 'Fido2']) ? (
                    <div className={"grid"}>
                        <Row gutter={16}>
                            <Col lg={12} span={24}>
                                <Button type="primary" className="btn-margin-right"
                                        style={{margin: '0 auto', width: '100%'}}
                                        onClick={() => removeFromAuthMethod('Fido2')}>继续使用密码登录</Button>
                            </Col>
                            <Col lg={12} span={24}>
                                <Button type="primary" className="btn-margin-right"
                                        style={{margin: '0 auto', width: '100%'}}
                                        onClick={() => removeFromAuthMethod('Password')}>通过通行密钥登录</Button>
                            </Col>
                        </Row>
                    </div>
                ) : compareArray(authMethod, ['Password']) ? (
                    <div>
                        <Form.Input field='password' label='密码' type='password' onChange={(e) => setPassword(e)}/>
                        <Button type="primary" className="btn-margin-right"
                                style={{margin: '0 auto', width: '100%'}} onClick={() => submitPasswordLogin()}>登录</Button>
                    </div>
                ) : compareArray(authMethod, ['Fido2']) ? (
                    <Button type="primary" className="btn-margin-right" style={{ margin: '0 auto', width: '100%' }}  onClick={() => submitWebAuthNLogin()}>通过通行密钥登录</Button>
                ) : (
                    <p>Unknown</p>
                )
            }
        </Modal>
    )
}

function PasswordModal({passwordVisible, setPasswordVisible, setVerificationVisible}) {

    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const submitPasswordChange = async () => {
        if (password !== passwordConfirm) {
            setErrorMessage("两次输入的密码不一致。")
            return
        }

        try {
            await axios.put(process.env.REACT_APP_API_HOST + '/api/v1/account/password?newPassword=' + password)
                .then(res => {
                    if (res.data.code === 200) {
                        setPasswordVisible(false)
                        setPassword('')
                        setPasswordConfirm('')
                        setErrorMessage('')
                    } else {
                        setErrorMessage(res.data.message)
                    }
                })
        } catch (e) {
            if (e.response && e.response.data) {
                const code = e.response.data.code;
                if (code === 402) {
                    setVerificationVisible(true)
                }
            }
        }
    }
    return (
        <Modal
            title="更改您的密码"
            visible={passwordVisible}
            onCancel={() => {
                setPasswordVisible(false)
                setPassword('')
                setPasswordConfirm('')
                setErrorMessage('')
            }}
            closeOnEsc={true}
        >
            <Form layout='vertical'>
                <Form.Input field='password' label='密码' type='password' onChange={(e) => setPassword(e)} />
                <Form.Input field='password' label='确认密码' type='password' onChange={(e) => setPasswordConfirm(e)} />
                <p style={{ color: 'red' }}>{errorMessage}</p>
                <Button type="primary" className="btn-margin-right" style={{ margin: '0 auto', width: '100%' }} onClick={() => submitPasswordChange()}>更改密码</Button>
            </Form>
        </Modal>

    )
}

export default function Account() {

    const [ verificationVisible, setVerificationVisible ] = React.useState(false)

    return (
        <div>
            <AccountPasskey setVerificationVisible={setVerificationVisible} />
            <Divider />
            <AccountPassword setVerificationVisible={setVerificationVisible} />
            <Divider />
            <AccountEmail />
            <Divider />
            <DevAccountJWT />
            <VerificationModal visible={verificationVisible} setVisible={setVerificationVisible}/>
        </div>
    )

}