import React from 'react'
import { Layout } from '@douyinfe/semi-ui';

import { useAtom } from 'jotai';
import { isLoginAtom, jwtTokenAtom } from '../atom';
import {useNavigate} from "react-router-dom";

const { Header } = Layout;

const commonStyle = {
    height: 64,
    lineHeight: '64px',
    background: 'var(--semi-color-fill-0)'
};

function AppHeader() {
    const [ isLogin, setIsLogin ] = useAtom(isLoginAtom);
    const [, setJWT ] = useAtom(jwtTokenAtom);

    const navigate = useNavigate()

    const logout = () => {
        setIsLogin(false);
        setJWT('');
    };

    const login = () => {
        navigate('/auth/login')
    }

    return (
        <Header style={commonStyle}>
            Header @ Template
            {
                isLogin? <button onClick={logout}>Logout</button> : <button onClick={login}>Login</button>
            }

        </Header>
    )
}

function AppHeaderMobile({ toggleSider }) {

    const [ isLogin, setIsLogin ] = useAtom(isLoginAtom);
    const [, setJWT ] = useAtom(jwtTokenAtom);

    const navigate = useNavigate()

    const logout = () => {
        setIsLogin(false);
        setJWT('');
    };

    const login = () => {
        navigate('/auth/login')
    }

    return (
        <Header style={commonStyle}>
            <button onClick={toggleSider}>三</button>
            Header @ Template (Mobile)
            {
                isLogin? <button onClick={logout}>Logout</button> : <button onClick={login}>Login</button>
            }
        </Header>
    )
}

export { AppHeader, AppHeaderMobile }