export function compareArray(arr1, arr2){
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
    }
    return true;
}

export function arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    bytes.forEach((byte) => binary += String.fromCharCode(byte));
    return window.btoa(binary);
};

export function coerceToArrayBuffer(thing, name) {
    if (typeof thing === "string") {
        // base64url to base64
        thing = thing.replace(/-/g, "+").replace(/_/g, "/");

        // base64 to Uint8Array
        var str = window.atob(thing);
        var bytes = new Uint8Array(str.length);
        for (var i = 0; i < str.length; i++) {
            bytes[i] = str.charCodeAt(i);
        }
        thing = bytes;
    }

    // Array to Uint8Array
    if (Array.isArray(thing)) {
        thing = new Uint8Array(thing);
    }

    // Uint8Array to ArrayBuffer
    if (thing instanceof Uint8Array) {
        thing = thing.buffer;
    }

    // error if none of the above worked
    if (!(thing instanceof ArrayBuffer)) {
        throw new TypeError("could not coerce '" + name + "' to ArrayBuffer");
    }

    return thing;
};

export async function passkeyLogin(options, sessionId) {
    const challenge = options.challenge.replace(/-/g, '+').replace(/_/g, '/');
    options.challenge = Uint8Array.from(atob(challenge), c => c.charCodeAt(0));

    options.allowCredentials.forEach(function (listItem) {
        var fixedId = listItem.id.replace(/_/g, "/").replace(/-/g, "+");
        listItem.id = Uint8Array.from(atob(fixedId), c => c.charCodeAt(0));
    });

    const credential = await navigator.credentials.get({publicKey: options});
    const data = {
        id: credential.id,
        rawId: arrayBufferToBase64(credential.rawId),
        // type: assertedCredential.type,
        type: 'PublicKey',
        extensions: credential.getClientExtensionResults(),
        response: {
            authenticatorData: arrayBufferToBase64(credential.response.authenticatorData),
            clientDataJSON: arrayBufferToBase64(credential.response.clientDataJSON),
            userHandle: arrayBufferToBase64(credential.response.userHandle),
            signature: arrayBufferToBase64(credential.response.signature)
        }
    };

    const makeCredentialOptions = {
        attestationResponse: data,
        sessionId
    };

    return makeCredentialOptions;
}

export async function passkeyCreate(options, sessionId, nickName) {
    const publicKey = {
        ...options,
        challenge: Uint8Array.from(atob(options.challenge), c => c.charCodeAt(0)),
        user: {
            ...options.user,
            id: Uint8Array.from(atob(options.user.id), c => c.charCodeAt(0))
        },
        excludeCredentials: options.excludeCredentials.map(cred => ({
            ...cred,
            id: Uint8Array.from(atob(cred.id), c => c.charCodeAt(0))
        }))
    };

    const credential = await navigator.credentials.create({ publicKey });
    const attestationResponse = {
        id: credential.id,
        rawId: arrayBufferToBase64(credential.rawId),
        //type: credential.type,
        type: 'PublicKey',
        response: {
            attestationObject: arrayBufferToBase64(credential.response.attestationObject),
            clientDataJSON: arrayBufferToBase64(credential.response.clientDataJSON)
        },
        extensions: credential.extensions || {} // Default to empty object if extensions are not present
    };

    const makeCredentialOptions = {
        attestationResponse,
        sessionId,
        nickName
    };

    return makeCredentialOptions;
}

export async function passkeyWTF(options, sessionId, nickName) {
    // Turn the challenge back into the accepted format of padded base64
    options.challenge = coerceToArrayBuffer(options.challenge);
    // Turn ID into a UInt8Array Buffer for some reason
    options.user.id = coerceToArrayBuffer(options.user.id);

    options.excludeCredentials = options.excludeCredentials.map((c) => {
        c.id = coerceToArrayBuffer(c.id);
        return c;
    });

    if (options.authenticatorSelection.authenticatorAttachment === null) options.authenticatorSelection.authenticatorAttachment = undefined;

    console.log("Credential Options Formatted from AddResponse to Options", options);

    const credential = await navigator.credentials.create({
        publicKey: options
    });

    const attestationResponse = {
        id: credential.id,
        rawId: arrayBufferToBase64(credential.rawId),
        //type: credential.type,
        type: 'PublicKey',
        response: {
            attestationObject: arrayBufferToBase64(credential.response.attestationObject),
            clientDataJSON: arrayBufferToBase64(credential.response.clientDataJSON)
        },
        extensions: credential.extensions || {} // Default to empty object if extensions are not present
    };

    const makeCredentialOptions = {
        attestationResponse,
        sessionId,
        nickName
    };

    return makeCredentialOptions;
}