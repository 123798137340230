import React, { useState } from 'react';
import axios from 'axios';
import {Button, Card, Col, Form, Row} from "@douyinfe/semi-ui";

import { passkeyCreate } from "../../utils/helper";

export default function AuthRegister() {
    const [ email, setEmail ] = useState('');
    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ passwordRegister, setPasswordRegister ] = useState(false);

    const submitPasswordRegister = () => {
        axios.post(process.env.REACT_APP_API_HOST + '/api/v1/auth/legacy/register', {
            email,
            username,
            password
        }).then(res => {
            if (res.data.code === 200) {
                alert("注册成功")
            } else {
                alert(res.data.message)
            }
        })
    }

    const usePasswordRegister = () => {
        setPasswordRegister(true);
    }

    const renderRegisterMethod = () => {
        if (!navigator.credentials || passwordRegister) {
            return (
                <Form layout='vertical'>
                    <Form.Input field='password' label='密码' type='password' onChange={(e) => setPassword(e)}/>
                    <Button type="primary" className="btn-margin-right" style={{ margin: '0 auto', width: '100%' }} onClick={submitPasswordRegister}>密码注册</Button>
                </Form>
            )
        } else {
            return (
                <div className={"grid"}>
                    <Row gutter={16}>
                        <Col lg={12} span={24}>
                            <Button type="primary" className="btn-margin-right" style={{ margin: '0 auto', width: '100%' }} onClick={handlePasskeyRegister}>使用通行密钥注册</Button>
                        </Col>
                        <Col lg={12} span={24}>
                            <Button type="primary" className="btn-margin-right" style={{ margin: '0 auto', width: '100%' }} onClick={usePasswordRegister}>继续使用密码注册</Button>
                        </Col>
                    </Row>
                </div>
            )
        }
    }
    const handlePasskeyRegister = async (e) => {
        e.preventDefault();
        const response = await axios.post(process.env.REACT_APP_API_HOST + '/api/v1/auth/webauthn/register/challenge', {
            email,
            username
        });
        const { options, sessionId } = response.data.data;
        const nickNameRandom = username + Math.floor(Math.random() * 1000);
        const makeCredentialOptions = await passkeyCreate(options, sessionId, nickNameRandom);
        await axios.post(process.env.REACT_APP_API_HOST + '/api/v1/auth/webauthn/register/submit', makeCredentialOptions);
    };

    return (
        <Card style={{ minHeight: '300px', width: '30vw', minWidth: '250px' }}>
            <h1>注册</h1>
            <Form layout='vertical'>
                <Form.Input filed='email' label='邮箱' onChange={(e) => setEmail(e)}/>
                <Form.Input field='username' label='用户名' onChange={(e) => setUsername(e)}/>
                { renderRegisterMethod() }
            </Form>
        </Card>
    );
}
