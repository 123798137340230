import React from 'react';
import { Nav } from '@douyinfe/semi-ui';

import { Link } from "react-router-dom";

export default function Navigator() {
    return (
        <Nav
            renderWrapper={({ itemElement, props }) => {
                const routerMap = {
                    Account: "/account",
                };
                return (
                    <Link style={{ textDecoration: "none" }} to={routerMap[props.itemKey]}>
                        {itemElement}
                    </Link>
                );
            }}
            items={[
                { itemKey: "Account", text: "账户管理" },
            ]}
            style={{ height: '100%'}}
        >
            <Nav.Footer collapseButton={true} />
        </Nav>
    );
}
