import React, { useEffect, useState} from 'react'
import {Col, Layout, Row} from '@douyinfe/semi-ui';
import { Outlet } from 'react-router-dom'

import { AppHeader, AppHeaderMobile } from './Headers'
import Navigator from "./Navigator";

export default function WebTemplate() {

    const { Footer, Sider, Content } = Layout;
    const [ isMobile, setIsMobile ] = useState(false);
    const [ showSider, setShowSider ] = useState(false);

    const toggleSider = () => {
        setShowSider(!showSider);

    }

    useEffect(() => {
        setIsMobile(window.innerWidth < 768);
    }, [])


    const commonStyle = {
        height: 64,
        lineHeight: '64px',
        background: 'var(--semi-color-fill-0)'
    };

    // body 的 padding 为 16px，所以内容区域的高度为 100vh - 128px (header & footer) - 16px
    return (
        <Layout className="components-layout-demo">
            { isMobile ? <AppHeaderMobile toggleSider={toggleSider} /> : <AppHeader /> }
            <Layout style={{ display: 'flex'}}>
                {
                    isMobile ?
                        showSider ? <Sider><Navigator /></Sider> : null
                        : <Sider><Navigator /></Sider>
                }
                <Content style={{ height: 'calc(100dvh - 128px - 16px)' }}>
                    <Row type="flex" justify="center">
                        <Col span={18}>
                            <Outlet/>
                        </Col>
                    </Row>
                </Content>
            </Layout>
            <Footer style={commonStyle}>Footer</Footer>
        </Layout>
    )
}